import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { apilink } from '../../data/fdata'

const NotApproved = () => {

  const [userData, setUserData] = useState([]);
  const [pageloading, setPageloading] = useState(false);
  const token = Cookies.get('_edushark_user_access_token');
  const his = useHistory();


  useEffect(async() => {
    if(token) {
      setPageloading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
           
              setUserData(res.data.userInfo)
            
  
          }
          setTimeout(() => {
            setPageloading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);
// console.log(userData)
  return (
    <div className="auth">

      <div className="container">
        <div className="row"> 
        <div className="col-lg-8 col-md-10 col-12 mx-auto">
            <div className="card p-3">
                <h4>Hi <span className='text-custom'>{userData?.name?.split(" ")[0]}</span> , Your Application is not approved yet .</h4>
                <p>Please contact 1234567895</p>
            </div>
        </div>
        </div>
        </div>
        </div>
  )
}

export default NotApproved