import React, { useEffect, useState } from 'react'
import './Courses.css'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { apilink, path } from '../../data/fdata'
import axios from 'axios'
const Courses = () => {
  const [courseList,setCourseList]=useState([])
  const [pageLoading,setPageLoading]=useState(false)

  const getcourseList=async()=>{

    setPageLoading(true)

const res=await axios.get(`${apilink}/api/course/getcourses`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCourseList(res.data.result)
    }
    setTimeout(() => {
      setPageLoading(false)
    }, 1500);
  }

  useEffect(()=>{
    getcourseList()

  },[])
  return (
    <>
    
    <div className="section_page">
    <div className="container-fluid    ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <h1 className='text-col title_font text-center'>Our Courses</h1>
              <div className="row mt-5">
                
                {
                  courseList.filter((v)=>v.cou_isSeen==true).map((val,ind)=>{
                    return(
                      <>
                      <div className=" col-xl-4 col-lg-6 col-md-6 col-sm-10 col-12 mb-4 mx-auto ">
                  <div className="card p-2">
                    <div className="div_box">
                    <img src={val.cou_image} alt="" className="courseimg" />
                    </div>
                    <div className="p-2 pt-4 text-center">
                    <h3 className='text-col text-size '>{val.cou_name}</h3>
                    <p className="short_p">{val.cou_description}</p>
                   
                   <div className="text-center mt-4">
                     <NavLink to={`/courses/${val._id}`} className='btn btn-warning '>Know More & Enroll</NavLink>
                   </div>
                    </div>
                  </div>
                </div>
                      </>
                    )
                  })
                }
               
              </div>
            </div>
          </div>
        </div>
    </div>

    {
  pageLoading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  )
}

export default Courses