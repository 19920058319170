import React, { useEffect } from 'react'
import './Success.css'
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { apilink, epochToFormattedDateTimeAndCheckExpiration } from '../../data/fdata';
const PaymentSuccess = () => {
  const urltoken = useLocation().search;
 const his= useHistory()
  
  const transaction_id= new URLSearchParams(urltoken).get('id');
  // const transaction_token= new URLSearchParams(urltoken).get('token');
// console.log(transaction_token)
  const verifyTransactionId=async()=>{
    
    const res=await axios.get(`${apilink}/api/payment/verifyTransactionId/${transaction_id}`)
    // console.log(res.data)
    if(!res.data.success)
    {
      his.push("/something-wrong")
    }else{
      setTimeout(() => {
        his.push("/student/dashboard")
      }, 2000);
    }
  }
 useEffect(()=>{
  if(transaction_id){

    verifyTransactionId(transaction_id)
    
  }else{
    his.push("/something-wrong")
  }
 },[transaction_id])
  return (
    <>
     <div className="success_page">
    <div className="container-fluid ">
        <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
                <div className="card p-3 text-center">
                    <h5 className='mb-3'>Your Transaction Id : <span className='text-custom'>{transaction_id}</span></h5>
                    <h5 className='text-success m-0'>Thank You For Your Payment . We Will Validate Your Details.</h5>
                    <div className="text-center my-3 mt-4">
                            <NavLink to="/student/dashboard" className="btn btn-primary">Back to Dashboard</NavLink>
                    </div>
                </div>
            </div>

        </div>


        </div>
    </div>
    </>
  )
}

export default PaymentSuccess