import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'

const AboutSection = () => {
  return (
    <>
    
    <div className="aboutsec">
        <div className="container">
            <div className="card p-3 ">
                <div className="row card_im">
                    <div className="col-lg-6 col-md-6 col-12 mb-3 ">
                        <img src="./image/student.png" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mb-3 d-flex justify-content-center  flex-column">
                        <h1>Turn Your ideas to live with us</h1>
                        <p className='mt-4 mb-4'>
                        Welcome to <span className='text-custom cur' >Edushark</span>, your go-to platform for online learning and skill development. At <span className='text-custom cur'>Edushark</span>, we're dedicated to helping you achieve your educational and career goals.
                        </p>
                        <div className="text-left">
                            <NavLink to="/aboutus" className='btn btn-warning '>Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    </>
  )
}

export default AboutSection