import React, { useEffect, useState } from 'react'
import '../../Student.css'
import SideBar from '../../component/sidebar/SideBar'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie';
import { apilink } from "../../../../data/fdata";
import axios from "axios";
import { useAlert } from 'react-alert';
import { CircularProgress } from '@mui/material';


const Setting = () => {
  const [password, setPassword] = useState('');
  const [npassword, setNPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  
  const [pageloading, setPageloading] = useState(false);
  const token = Cookies.get('_edushark_user_access_token');
  const his = useHistory();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);



  useEffect(async() => {
    if(token) {
      // setPageloading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
   
            
  
          }
          // setTimeout(() => {
          //   setPageloading(false)
          // }, 2000);
         
    }else{
      his.push("/login")
    }
  }, []);


  const onPasswordUpdate=async(e)=>{
    e.preventDefault()
    setLoading(true)

    if(npassword.length <6) {

      setTimeout(() => {
       alert.error("Password Length must be at least 6 characters")
      setLoading(false);
      }, 2000);

    }
    else if(npassword !=cpassword ) {

      setTimeout(() => {
       
      alert.error("Password not match")
      setLoading(false);
      }, 2000);
      
    }else{
      let data={
        password,
        npassword,
      }
      const res=await axios.post(`${apilink}/api/user/updateuserPassword`,data,{
        headers: {
          Authorization: token,
        },
      })
      // console.log(res.data)

      if(res.data.success)
      {



        setTimeout(() => {       
          alert.success(res.data.msg)
          setLoading(false);
          }, 2000);


          setTimeout(() => {       
            Cookies.remove('_edushark_user_access_token');
      localStorage.removeItem('_edushark_user_access_login');
        console.clear();
        window.location.href = '/login';
            }, 4000);

      }else{
        setTimeout(() => {       
          alert.error(res.data.msg)
          setLoading(false);
          }, 2000);
      }
    }

  }

  return (
    <>
    
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-lg-6 col-md-8 col-12">
                <div className="card p-3">
                  <h4 className='text-center'>Update Password</h4>
                  <form onSubmit={onPasswordUpdate} className='mt-4'>
                  <div class="form-group">
                    <input
                      type="password"
                      placeholder="Enter Old Password"
                      class="form-control form_height"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      placeholder="Enter New Password"
                      class="form-control form_height"
                      name="npassword"
                      value={npassword}
                      onChange={(e) => setNPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      class="form-control form_height"
                      name="cpassword"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                  </div>
                 
                 
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : ' btn btn-primary'
                      }
                      disabled={loading}
                    >
                      Update Password
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={35} />
                    </div>
                  )}
                  </form>
                </div>
              </div>
            </div>

            
          </div>
          </div>
        </div>
      </div>

      {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  )
}

export default Setting