import React, { useEffect, useState } from 'react'
import './College.css'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { apilink } from '../../data/fdata'
const College = () => {

  const [collegeList,setCollegeList]=useState([])
  const [pageLoading,setPageLoading]=useState(false)

  const getCollegeList=async()=>{
    setPageLoading(true)

const res=await axios.get(`${apilink}/api/college/getColleges`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCollegeList(res.data.result)
    }
    setTimeout(() => {
      setPageLoading(false)
    }, 1500);
  }

  useEffect(()=>{
    getCollegeList()

  },[])

  return (
    <>
     <div className="section_page">
    <div className="container-fluid    ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <h1 className='text-col title_font text-center'>Our Colleges</h1>
              <div className="row mt-5">
                {
                  collegeList?.map((val,ind)=>{
                    return (
                      <>
                      
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                  <div className="card p-2">
                    {
                      val?.col_images.length > 0   && <img src={val.col_images[0] } alt=""  className='clg_img' />
                    }
                    <div className="py-3 px-2 text-center text-col">
                    <h4 className='text-size'>{val.col_name}</h4>
                   
                    <NavLink to={`/colleges/${val._id}`} className="btn btn-warning mt-4">View Details</NavLink>
                    </div>
                  </div>
                </div>
                      </>
                    )
                  })
                }
                
                
               
              </div>
            </div>
          </div>
        </div>
    </div>

    {
  pageLoading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  )
}

export default College