import React from 'react'
import './Policy.css'
const ReturnPolicy = () => {
  return (
   <>
   
   <div className="section_page">
    <div className="container   mt-5">
   <div className="mt-2">
  <h4> RETURN/REFFUND POLICY</h4>
 <p>Last updated November 05, 2023</p>
 <p>Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase for any reason, you may return it to us for a full refund or an exchange. Please see below for more information on our return policy.
 </p>
   </div>




<div className='mt-5'>
<h4>RETURNS </h4>
 <p>All returns must be postmarked within seven 7 days of the purchase date. All returned items must be in new and unused condition, with all original tags and labels attached.
</p>
 
</div>

<div className='mt-5'>
<h4>RETURN PROCESS </h4>
 <p>To return an item, place the item securely in its original packaging and If you have received a damaged or defective product or if it is not as described, you can raise a replacement request on the Website within 7 days of receiving the product. </p>

<p>Then mail your return to the following address:</p>
<p> Edushark </p>
<p> Attn: Returns</p>
<p> Guripara, Azimganj </p>
<p> Murshidabad, Azimganj, West Bengal , 742122 , India.
</p>
<p>You may also use the prepaid shipping label enclosed with your package. Return shipping charges will be paid or reimbursed by us.</p>


</div>
<div className='mt-5'>
<h4>REFUNDS </h4>
 <p>After receiving your return and inspecting the condition of your item, we will process your return or exchange. Please allow at least three (3) days from the receipt of your item to process your return or exchange. We will notify you by email when your return has been processed. </p>
 <p>You Will receive refund amount within 5-7 bank working days in your Bank Account or in Wallet.</p>

</div>

<div className='mt-5'>
<h4>EXCEPTIONS</h4>
 <p>The following items cannot be returned or exchanged:</p>
 <ul className='pl-4'>
    <li>Admission Fees, Semester Fees and any other Fees. </li>
 <li>Video course.</li>
 </ul>
<p> For defective or damaged products, please contact us at the contact details below to arrange a refund or exchange.</p>

</div>

<div className='mt-5'>
<h4>Please Note</h4>
 <p>Refund of application fee is applicable only if the student's application is rejected due to seat availability and Subjct availability.</p>
 <ul className='pl-4'>
    <li> Successful pick-up of the product is subject to the following conditions being met: • Correct and complete product (with the original brand/product ld/undetached MRP tag/product's original packaging/freebies and accessories). The product should be in unused</li>
 </ul>

</div>
<div className="mt-4">
<h4>QUESTIONS</h4>
 <p>If you have any questions concerning our return policy, please contact us at:</p>
<p><a href="mailto:iedushark@gmail.com"> iedushark@gmail.com</a></p>

</div>



        </div>
    </div>
   </>
  )
}

export default ReturnPolicy