import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import React, { useState } from 'react';

import { apilink } from '../../data/fdata';
import validator from 'validator';

import Cookies from 'js-cookie';
import {useHistory ,  useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ResetPassword = () => {
    const [npassword, setNPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [passwordVis, setPasswordVis] = useState("password");
  const [col, setCol] = useState('');

  const his = useHistory();

  const urltoken = useLocation().search;
  
  const accesstoken= new URLSearchParams(urltoken).get('token');
// console.log(accesstoken)
  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (npassword.length < 6) {
     setTimeout(() => {
        setStatus(true);
        setMsg('Password should be more than 5 characters');
        setLoading(false)
        setCol("alert-warning")

     }, 2000);
    } else if (npassword !== cpassword) {
     setTimeout(() => {
        setStatus(true);
        setMsg('Both Password not matched');
        setLoading(false)
        setCol("alert-warning")

     }, 2000);
    } 
    else{
      if(validator.isStrongPassword(npassword, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }))
      {
        let res = await axios.post(
          `${apilink}/api/user/resetPassword`,
          {
           
           password: npassword,
           activation_token:accesstoken
          }
        );
        if (res.data.success) {
          setTimeout(() => {
              setStatus(true);
              setMsg(res.data.msg);
          setCol("alert-success")

              setNPassword("")
              setCPassword("")
              setLoading(false)
          }, 1500);
          setTimeout(() => {
              his.push('/login');
          }, 3000);
        } else {
          setTimeout(() => {
              setStatus(true);
          setMsg(res.data.msg);
          setCol("alert-warning")

          setLoading(false)
          }, 2000);
        }

      }else{
        setTimeout(() => {
          setStatus(true);
          setCol("alert-warning")
        setMsg(
          'Use strong password: 1 number, 1 uppercase, 1 lowercase, 1 special character'
        );
      setLoading(false)
      }, 2000);
        
      }
        
    }
  };

  return (
   <>
   
   
   <div className="auth">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="card p-3">
                {status ? (
                  <>
                    <div class={`alert ${col} alert-dismissible`}>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {msg}
                    </div>
                  </>
                ) : null}

                <h3 className="text-center pb-3">Reset Password</h3>

                <br />
                <form onSubmit={onSub} className="">
                  <div class="form-group">
                    <input
                      type={passwordVis}
                      placeholder="New Password"
                      class="form-control formh"
                      name="lname"
                      value={npassword}
                      onChange={(e) => setNPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type={passwordVis}
                      placeholder="Confirm Password"
                      class="form-control formh"
                      name="lname"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                  </div>
               {
                passwordVis =="password" ?    <p className=' fn_12 cur re_p' onClick={()=>setPasswordVis("text")}>View password</p> :    <p className=' fn_12 cur re_p' onClick={()=>setPasswordVis("password")}>Hide password</p>
               }

                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                      Reset Password
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress size={35} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default ResetPassword