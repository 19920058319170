import React, { useEffect, useState } from 'react'
import './Courses.css'
import { apilink, path } from '../../data/fdata'
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import axios from 'axios'
import parse from "html-react-parser";
import { CircularProgress } from '@mui/material'
const CourseDetails = () => {
  const [videoshow,setVideoshow]=useState(false)
  const [pageLoading,setPageLoading]=useState(false)
  const [loading,setLoading]=useState(false)

  const [errorFound, setErrorFound] = useState(false);
  const [popEnroll, setPopEnroll] = useState(false);

  const history= useHistory()
 const {coursid}= useParams()
 const [courseDetails,setCourseDetails]=useState({})




 const getCourseById=async(cid)=>{
  setPageLoading(true)
  const res=await axios.get(`${apilink}/api/course/getCourseById/${cid}`)
  // console.log(res.data)
  if(res.data.success)
  {
    setCourseDetails(res.data.result)

    if(!res.data.result.cou_isSeen)
      {
        history.push("/something-wrong")
      }
  }
  else{
    setErrorFound(true)
  }
  setTimeout(() => {
    setPageLoading(false)
  }, 1500);

 }

 useEffect(()=>{
  if(coursid)
  {
    getCourseById(coursid)
  }
  
 },[coursid])

 if(errorFound)
  {
    history.push("/something-wrong")
  }

  const onEnrollCheck=(e)=>{
    // console.log(e.target.checked)
    if(e.target.checked)
    {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        history.push(`/courses/${coursid}/enroll`)
      }, 1000);
      
    }
  }

  return (
    <>
      <div className="section_page">
    <div className="container-fluid    ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
            
              <div className="row mt-2">
                <div className="col-lg-8 col-md-7 col-12 mb-3">
                  <h1 className='text-col text-sml font-weight-bold '>{courseDetails?.cou_name}</h1>
                  <p className='text-col w-change '>{courseDetails?.cou_description}</p>

                </div>
                <div className="col-lg-4 col-md-5 col-12 mb-3">
                  <div className="det_card p-3 text-white text-center">
                    <h2>{courseDetails?.cou_duration} Years Course</h2>
                    <h4 className='mt-3'>Fee starting from ₹{courseDetails?.cou_price}</h4>
                    <p className='m-0 fn_12'>*Course fees can vary significantly between institutions.</p>
                   <div className="px-2 px-lg-5 mt-4">
                   {/*  */}
                   <button class="btn btn-light m-1 btn-block font-weight-bold" onClick={()=>setPopEnroll(true)}>Enroll Now</button>
                
                   {
                          popEnroll && <div className="model_box">
                            <div className="inner_model" >
                              <div className='cross' onClick={()=>setPopEnroll(false)}>&times;</div>
                              <div className="py-2 text-dark">
                                {
                                  courseDetails?.cou_isActive ? <>
                                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={40} />
                    </div>
                  )}

                  <div class="form-check mt-2">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                         onChange={onEnrollCheck}
                         
                          required
                        />
                       I agree that I understand the eligibility criteria given below.
                      </label>
                    </div>
                    {/* <button  onClick={()=>setPopEnroll(false)} className='sml_btn bg-primary mt-4'>Cancel</button> */}


                                  
                                  </> :(
                                    <>
                                    <h5>Enrollment Not Yet Started</h5>
                                    </>
                                  )
                                }
                                
                              </div>
                            </div>
                            

                          </div>
                         }
                    
                  
                   </div>

                  </div>
                  
                  </div>
               
               
              </div>
              <div className="row mt-4">
                <div className="col-lg-10 col-md-12 mx-auto ">
                  <div className="img_rel">
                  <img src={courseDetails?.cou_image} alt="" className='cur_Det_img' />
                  <div className="img_abs">
                   <div className="play_btn cur" onClick={()=>setVideoshow(true)}>
                    <i className='fa fa-play '></i>
                   </div>

                  </div>
                  </div>
                  
                </div>
                

              </div>

              <div className="row">
                <div className="col-xl-10 col-lg-12 col-md-12 mx-auto">
                <div className="row mt-5 pt-5">
                  <div className="col-lg-6 col-md-6 col-12 mb-2">
                <h3>Course Provided by Collages</h3>
                <hr />
                <ul className='pl-4' >
                  
                 {
                  courseDetails?.cou_colleges?.map((v,ind)=>{
                    return (
                      <>
                      <li key={ind}><NavLink to={`/colleges/${v.collegeId}`}>{v.collageName}</NavLink></li>
                      </>
                    )
                  })
                 }
                  
                </ul>

               </div>
               <div className="col-lg-6 col-md-6 col-12 mb-2">
               <h3>Course Fee Structure</h3>
                <hr />
                <div className="row">
                 {
                  courseDetails?.cou_sem_prices?.map((val,ind)=>{
                    return <>
                    
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3 " key={ind}>
                    <div className=" border_card p-2">
                      <h4>{val.semtitle}</h4>
                      <p>₹{val.semfees}</p>
                    </div>
                  </div>
                    </>
                  })
                 }
                  
                 
                  
                </div>

                
               </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-10 col-lg-12 col-md-12 mx-auto">
                <div className="row mt-5 pt-5">
                  <div className="col-lg-8 col-md-10 col-12 mb-2 other_tab">
                <h3>Other Details</h3>
                <hr />
              

                  {
                    courseDetails?.cou_other_details && (
                      <>
                      {
                        parse(`${courseDetails.cou_other_details}`)
                      }
                      </>
                    )
                  }


               </div>
              
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
    </div>


{
  videoshow && (
    <>

<div className="video_prev">
<div className="video_prev_box">
  <div className="cross_b" onClick={()=>setVideoshow(false)}>
    &times;
  </div>
  <iframe width="100%" height="100%"  style={{borderRadius:'15px'}} src={courseDetails?.cou_video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

</div>


</div>
    </>
  )
}



{
  pageLoading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}

    </>
  )
}

export default CourseDetails