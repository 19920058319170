import React from 'react'
import './Success.css'
import { NavLink } from 'react-router-dom';

const PaymentFail = () => {
  return (
    <>
     <div className="success_page">
    <div className="container-fluid ">
        <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mx-auto">
                <div className="card p-3 text-center">
                    <h5 className='text-danger m-0'>Oops, something went wrong!</h5>
                    <div className="text-center my-3 mt-4">
                            <NavLink to="/student/dashboard" className="btn btn-primary">Back to Dashboard</NavLink>
                    </div>
                </div>
            </div>

        </div>


        </div>
    </div>
    </>
  )
}

export default PaymentFail