import React, { useEffect, useState } from "react";
import "./Purches.css";
import { apilink, path, removeSpecialCharacters } from "../../data/fdata";
import axios from "axios";
import { useAlert } from "react-alert";
import {
  NavLink,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";



const BookDetails = () => {
    let url = "https://edushark.in";
    let description = "Edushark";

  const [bookDet, setBookDet] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const token = Cookies.get("_edushark_user_access_token");

  const alert = useAlert();
  const his = useHistory();
  const { bookid } = useParams();

  const getBookDet = async () => {
    setPageLoading(true);

    const res = await axios.get(
      `${apilink}/api/purchesbook/getcontentbyid/${bookid}`
    );
    // console.log(res.data);
    if (res.data.success) {
      setBookDet(res.data.result);
    } else {
      alert.error(res.data.msg);
    }
    setTimeout(() => {
      setPageLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (bookid) {
      getBookDet();
    }
  }, [bookid]);

  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>{bookDet?.bookname && `${bookDet?.bookname} published by ${bookDet?.bookpublisher}`}</title>
                <meta name="description" content={bookDet?.bookname && `${bookDet?.bookname} published by ${bookDet?.bookpublisher}`} />


                
<meta property="og:title" content={bookDet?.bookname && bookDet?.bookname} />
<meta property="og:description" content={bookDet?.bookname && `${bookDet?.bookcategory} - ${bookDet?.booksem} - ${bookDet?.bookname} book published by ${bookDet?.bookpublisher}`} />
<meta name="og:image" content={bookDet?.bookimage?.length && bookDet.bookimage[0] } />


<meta name="twitter:card" content="summary_large_image" />
  
<meta name="twitter:title" content={bookDet?.bookname && bookDet?.bookname} />
<meta name="twitter:description" content={bookDet?.bookname && `${bookDet?.bookcategory} - ${bookDet?.booksem} - ${bookDet?.bookname} book published by ${bookDet?.bookpublisher}`} />
<meta name="twitter:image" content={bookDet?.bookimage?.length && bookDet.bookimage[0] } />
            </Helmet>

      <div className="section_page">
        <div className="container   ">
          {
            bookDet?.bookname?.length > 0       ?(
                <>
                
                <div className="row mt-5">
            <div className="col-md-6 col-12">
                <div className="book_cover ">
                <div className="book_card_abs cur">
                            <div className="dropdown">
                              <i className="fa fa-share-alt"></i>
                              <div class="dropdown-content">
                                <a
                                  href={`https://www.facebook.com/sharer/sharer.php?u=${`${path}/purchase-books/${bookDet._id}/${removeSpecialCharacters(bookDet.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`}`}
                                  target="_blank"
                                  className="text_fb"
                                >
                                  Share with Facebook
                                </a>

                                <a
                                  href={`https://twitter.com/intent/tweet?url=${`${path}/purchase-books/${bookDet._id}/${removeSpecialCharacters(bookDet.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`}&text=${encodeURI(
                                    description
                                  )}`}
                                  className="text_tw"
                                  target="_blank"
                                >
                                  Share with Twitter
                                </a>
                                <a
                                  href={`https://api.whatsapp.com/send/?text=${`${path}/purchase-books/${bookDet._id}/${removeSpecialCharacters(bookDet.bookname)?.trim().toLowerCase().replace(/\s/g, "-")}`}`}
                                  className="text_wh"
                                  target="_blank"
                                >
                                  Share with Whatsapp
                                </a>
                              </div>
                            </div>
                          </div>
                {bookDet.bookimage?.length && (
                            <img
                              src={bookDet.bookimage[0]}
                              alt=""
                              className=" "
                             
                            />
                          )}
                </div>
           
            </div>
            <div className="col-md-6 col-12">
         
                                        <h5 className="mb-3 mt-3">
                                          <span className="text-custom">
                                            Name: &nbsp;
                                          </span>
                                          {bookDet.bookname}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Author: &nbsp;
                                          </span>
                                          {bookDet.bookauthor}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Language: &nbsp;
                                          </span>
                                          {bookDet.booklang}
                                        </h5>
                                        

                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Category: &nbsp;
                                          </span>
                                          {bookDet.bookcategory}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Semester or Year: &nbsp;
                                          </span>
                                          {bookDet.booksem}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Publisher: &nbsp;
                                          </span>
                                          {bookDet.bookpublisher}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Price: &nbsp;
                                          </span>
                                          {bookDet.bookprice}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Number of Books: &nbsp;
                                          </span>
                                          {bookDet.booknumber}
                                        </h5>
                                        <h5 className="mb-3">
                                          <span className="text-custom">
                                            Number of Pages: &nbsp;
                                          </span>
                                          {bookDet.bookpages}
                                        </h5>
                                        <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    his.push(`/purchase-books/${bookDet._id}/buynow`)
                                  }
                                >
                                  Buy Now
                                </button>
                                      
                </div>
          </div>
                </>
            ):(
                <>
                <div className="text-center my-5">
                <h2>Coming Soon...</h2>
                </div>
                </>
            )
          }
        </div>
      </div>

      {pageLoading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default BookDetails;
