import React from 'react'
import './About.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { path } from '../../data/fdata'

const About = () => {
 const his= useHistory()
  return (
    <>
    
    <div className="section_page">
    <div className="container-fluid    ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
             
             <div className="row">
              <div className="col-lg-7 col-md-12 mx-auto mb-3">
                <h2 className='text-col title_font '>About Us</h2>
                <h5>Welcome to <span className='text-custom cur' onClick={()=>his.push("/")} >Edushark</span>, your go-to platform for online learning and skill development. At <span className='text-custom cur' onClick={()=>his.push("/")}>Edushark</span>, we're dedicated to helping you achieve your educational and career goals.</h5>
                <p className='fn_18 text-custom mt-3 m-0'><b>Our Mission:</b></p>
                <p>Our mission is to make learning accessible to everyone, anywhere, and at any time. We believe that education is the key to personal and professional growth, and we're here to empower you on your learning journey.</p>

                <p className='fn_18 text-custom mt-3 m-0'><b>What We Offer:</b></p>
                <p><b>A Diverse Range of Courses:</b> Explore a wide variety of courses covering topics from technology and business to art and personal de</p>

               <p><b>Expert Instructors:</b> Learn from experienced instructors who are passionate about sharing their knowledge and expertise with you. Our instructors are carefully selected to ensure you receive top-notch education.</p>
                <p><b>Flexible Learning:</b> Study at your own pace, on your own schedule. Our app is designed to fit seamlessly into your busy life, so you can learn whenever and wherever it's convenient for you.</p>
                  <p><b>Interactive Learning:</b> Engage with the course material through interactive quizzes, assignments, and discussions. Learning is not just about consuming content; it's about actively participating and applying what you've learned.</p>
              </div> 


              <div className="col-lg-5 col-md-12 mx-auto mb-3">
               
              <img
                    src={`${path}/image/access.jpg`}
                    alt=""
                    className="access_img"
                  />
                </div>
             </div>

              </div>
              
              <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto mt-5">
                <img src={`${path}/image/AboutUs.png`} className='abo_img' alt="" />
                </div>
              </div>
              </div>
              </div>
    </>
  )
}

export default About