import React from 'react'

const BigText = () => {
  return (
    <>
    <div className='big_text'>
    <p>Welcome  &nbsp; to   <span class="fancy">EDUSHARK</span></p>
    </div>
    
    
    </>
  )
}

export default BigText