import React, { useEffect, useState } from 'react'
import SideBar from '../../component/sidebar/SideBar'
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAlert } from 'react-alert';
import { apilink } from '../../../../data/fdata';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ContentDetails = () => {
    const [pageloading, setPageloading] = useState(false);
    const token = Cookies.get('_edushark_user_access_token');
    const his = useHistory();
    
    const {cid}=useParams()
    const alert= useAlert()

    const [contentList,setContentList]=useState([])
    const [preload, setPreload] = useState(false);

    useEffect(async() => {
        if(token) {
          setPageloading(true)
            const res=await axios.get(`${apilink}/api/user/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              // console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_user_access_token');
               localStorage.removeItem('_edushark_user_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
             
                  
                
      
              }
              setTimeout(() => {
                setPageloading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);

      const getcontentbyid=async()=>{
        const res=await axios.get(`${apilink}/api/purchescourse/getcontentbyid/${cid}`)
        // console.log(res.data)
        setPreload(true)
        if(res.data.success)
        {
            setContentList(res.data.result )
          setTimeout(() => {
            setPreload(false)
          }, 1500);


        }else{
            alert.error(res.data.msg)
            
                setPreload(false)
           
        }
      }
      useEffect(()=>{
        if(cid)
        {
            getcontentbyid()
        }
        
      },[cid])
  return (
   <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid mt-4">
          <div className="row mb-5">
            {
                preload ?(
                    <>
                    <SkeletonTheme baseColor="#f2f2f2" highlightColor="#fff">

                    
                    <div className="col-lg-5 col-md-6 col-12 mx-auto mb-3">
                    <Skeleton height={300} />

            </div>
            <div className="col-lg-7 col-md-6 col-12 mx-auto mb-3">
            <Skeleton height={50} width={180} />
             
            <Skeleton height={20}  />
            <Skeleton height={20}  />
            <Skeleton height={20}  />
<br />
            <Skeleton height={30} width={150}  />
            <Skeleton height={30} width={150}  />
              
            
              </div>
              </SkeletonTheme>
                    </>
                ):(
                    <>
                    <div className="col-lg-5 col-md-6 col-12 mx-auto mb-3">
              <img src={contentList.cor_image} alt="" className='purches_img' />

            </div>
            <div className="col-lg-7 col-md-6 col-12 mx-auto mb-3">
              <h2 className='text-custom font-weight-bold'>{contentList.cor_name}</h2>
              <p className='m-0'>{contentList.cor_description}</p>
              <br />
              <h5>Price: <span className='text-custom'>{contentList.cor_price}</span></h5>

              <h5 className='mb-4'>Duration: <span className='text-custom'>{contentList.cor_duration}</span></h5>
              
            
              </div>
                    
                    </>
                )
            }
            </div>
            <h3>All Video Content</h3>
             <div className="row mt-4">
                {
                    preload ?(
                        <>
                        <SkeletonTheme baseColor="#f2f2f2" highlightColor="#fff">
                        <div className="col-12">
                        {
                        contentList?.cor_content?.map((val,ind)=>{
                            return(
                                <>
                                
                                <div className="card p-3 mb-3" key={ind}>
                        <div>
                        <Skeleton height={40} width={250}  />
       
                        </div>
                        
                        <Skeleton height={22}   />
                        <Skeleton height={22}   />

                    </div>
                                </>
                            )
                        })
                    }
                    </div>
                        </SkeletonTheme>
                        </>
                    ):(
                        <>

<div className="col-12">
                   
                   {
                    contentList?.cor_content?.length > 0 ?(
                        <>
                         {
                        contentList?.cor_content?.map((val,ind)=>{
                            return(
                                <>
                                
                                <div className="card p-3 mb-3" key={ind}>
                        <div>
                       <h5 className='text-custom'> <a href={val.video_url} target='_blank'>{val.title}</a></h5>
                        </div>
                        <p className='m-0'>{val.sml_desc}</p>
                       
                    </div>
                                </>
                            )
                        })
                    }
                        </>
                    ):(
                        <>
                        <div className="my-5 text-center">
<h5>No Item Found</h5>
                        </div>
                        
                        
                        </>
                    )
                   }
                </div>
                        
                        </>
                    )
                }
               
             </div>

            
          </div>
          </div>
        </div>
      </div>
   
   
   {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
   
   </>
  )
}

export default ContentDetails