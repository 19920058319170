import React, { useEffect, useState } from 'react'
import HeroCompo from './HeroCompo'
import CompanySlider from './CompanySlider'
import './Home.css'
import AboutSection from './AboutSection'
import AfterHero from './AfterHero'
import { apilink } from '../../data/fdata'
import axios from 'axios';

const Home = () => {

  const [courseList,setCourseList]=useState([])

  const getcourseList=async()=>{

const res=await axios.get(`${apilink}/api/course/getcourses`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCourseList(res.data.result)
    }
  }

  useEffect(()=>{
    getcourseList()

  },[])


  return (
    <>
    <HeroCompo/>
    <AfterHero  courseList={courseList} />
    <AboutSection/>
    </>
  )
}

export default Home