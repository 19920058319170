import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { path } from '../data/fdata'

const Wrong = () => {
  return (
    <>
    <div className="wrong">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-12 mx-auto">
                    <div className="p-3">
                        <img src={`${path}/image/wrong.png`} alt="" className='wrong_img' />
                        <div className="text-center p-3">
                            <p>Something Wrong! 😢😢 </p>
                            <NavLink to="/" className="sm_btn">Go Back</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    </>
  )
}

export default Wrong