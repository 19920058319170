import React, { useEffect, useState } from "react";
import "./Purches.css";
import { apilink, getFormattedDate, stateList } from "../../data/fdata";
import axios from "axios";
import { useAlert } from "react-alert";
import {
  NavLink,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";

const BuyNow = () => {
  const [pageloading, setPageloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({});

  const [name, setName] = useState("");
  const [billing_email, setBilling_email] = useState("");
  const [billing_phone, setBilling_phone] = useState("");
  const [billing_address, setBilling_address] = useState("");
  const [billing_address_2, setBilling_address_2] = useState("");
  const [billing_city, setBilling_city] = useState("");
  const [billing_pincode, setBilling_pincode] = useState("");
  const [billing_state, setBilling_state] = useState("");
  const [billing_country, setBilling_country] = useState("India");
  const [pickup_location, setPickup_location] = useState("");
  const [officePin_code, setOfficePin_code] = useState("");

  const [book_id, setBook_id] = useState("");
  const [book_name, setBook_name] = useState("");
  const [bookNumber, setBookNumber] = useState(0);

  const [book_pub, setBook_pub] = useState("");
  const [book_lang, setBook_lang] = useState("");

  const [book_price, setBook_price] = useState(0);
  const [book_qty, setBook_qty] = useState("1");
  const [book_l, setBook_l] = useState("");
  const [book_wi, setBook_wi] = useState("");
  const [book_h, setBook_h] = useState("");
  const [book_w, setBook_w] = useState("");

  const [total_book_price, setTotal_book_price] = useState(0);
  const [payment_with_shipping, setPayment_with_shipping] = useState(0);

  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [deliveryId, setDeliveryId] = useState("");
  const [deliveryList, setDeliveryList] = useState([]);


  const [distList, setDistList] = useState([]);


  const [stepcount, setStepcount] = useState(1);
  const [apiToken, setApiToken] = useState("");

  const token = Cookies.get("_edushark_user_access_token");

  const alert = useAlert();
  const his = useHistory();
  const { bookid } = useParams();

  useEffect(async () => {
    if (token) {
      setPageloading(true);
      const res = await axios.get(`${apilink}/api/user/authVerify`, {
        headers: {
          Authorization: token,
        },
      });
      // console.log(res.data)
      if (!res.data.success) {
        Cookies.remove("_edushark_user_access_token");
        localStorage.removeItem("_edushark_user_access_login");
        console.clear();
        window.location.href = "/login";
      } else {
        setUserData(res.data.userInfo);
        setBilling_email(res.data.userInfo.email);
        setBilling_phone(res.data.userInfo.phone);
        setName(res.data.userInfo.name.toUpperCase());
      }
      setTimeout(() => {
        setPageloading(false);
      }, 1500);
    } else {
      his.push("/login");
    }
  }, []);

  const getpickuplocation = async () => {
    const res = await axios.get(`${apilink}/api/shiprocket/loginUser`);

    if (res.data.success) {
      setApiToken(res.data.result.token);

      const ress = await axios.post(
        `${apilink}/api/shiprocket/findpickuplocation`,
        {
          shiptoken: res.data.result.token,
        }
      );
      // console.log(ress.data.result)

      if (ress.data.success) {
        setPickup_location(
          `${ress.data.result.data.shipping_address[0].pickup_location}`
        );
        setOfficePin_code(ress.data.result.data.shipping_address[0].pin_code);
      } else {
        alert.error(ress.data.msg);
      }
    } else {
      alert.error(res.data.msg);
    }

 
  };

  const getcontentbyid = async () => {
    const res = await axios.get(
      `${apilink}/api/purchesbook/getcontentbyid/${bookid}`
    );
    // console.log(res.data)
    if (res.data.success) {
      setBook_id(res.data.result._id);
      setBook_name(res.data.result.bookname);
      setBook_price(res.data.result.bookprice);
      setTotal_book_price(res.data.result.bookprice);
      setBookNumber(res.data.result.booknumber);
      if(res.data.result.booknumber>1)
      {
        setPayment_with_shipping(Number(res.data.result.bookprice) + 180);
        setDeliveryPrice(180)
      }else{
        setPayment_with_shipping(Number(res.data.result.bookprice) + 80);
      setDeliveryPrice(80)
      }

      
      setBook_pub(res.data.result.bookpublisher);
      setBook_lang(res.data.result.booklang);

      setBook_l(res.data.result.bookdimension_length);
      setBook_wi(res.data.result.bookdimension_width);
      setBook_h(res.data.result.bookdimension_height);
      setBook_w(res.data.result.bookweight);
    } else {
      alert.error(res.data.msg);
    }
  };

  useEffect(() => {
    getpickuplocation();
    getcontentbyid();
  }, []);

  const onSubmitCart = async (e) => {
    e.preventDefault();

    if (stepcount == 1) {
      setStepcount(2);
    } else {
      setLoading(true);
      setPageloading(true)

      let ordate=getFormattedDate(new Date())

      let oiddate=Date.now();

      const data = {
        authtoken:token,
        token: apiToken,        
        order_id: oiddate,
        order_date: ordate,
        pickup_location: pickup_location,

        billing_customer_name: name,
        billing_last_name: "",
        billing_address: billing_address,
        billing_address_2: billing_address_2,
        billing_city: billing_city,
        billing_pincode: billing_pincode,
        billing_state: billing_state,
        billing_country: billing_country,
        billing_email: billing_email,
        billing_phone: billing_phone,
        shipping_is_billing: true,

        order_items: [
          {
            name: book_name,
            sku: book_id,
            units: book_qty,
            selling_price: total_book_price,
            single_price:book_price,       
          },
        ],
        payment_method: "Prepaid",
        sub_total: total_book_price, 
               
        length: book_l,
        breadth: book_wi,
        height: book_h,
        weight: book_w,
        delivery_price: deliveryPrice ,
        payment_with_shipping,
        MUID: "MUID" + oiddate,
        transactionId: 'T' + oiddate,

      };

      const paydata={
        billing_customer_name:name,
        billing_phone: billing_phone,               
        payment_with_shipping,
        MUID: "MUID" + oiddate,
        transactionId: 'T' + oiddate,
        paidfor:"orderbookTable",
        pay_userID:userData?._id
      }

      // console.log(data)

      // fakeorder

      // console.log(paydata)

      const result=await axios.post(`${apilink}/api/fakeorder/addFakeOrder`,data);
      // console.log(result.data)


      const payresult=await axios.post(`${apilink}/api/payment/addPayment`,paydata);

      setTimeout(() => {
                 window.location.href=payresult.data.url
                 }, 1000);
// console.log(payresult.data.url)

// if(result.data.success) {
// // console.log(result.data.url)

//   const res = await axios.post(
//         `${apilink}/api/shiprocket/placedOrder`,
//         data,
//         {
//           headers: {
//             Authorization: token,
//           },
//         }
//       );
//       // console.log(res.data)
     

//       if(res.data.success) {
//         const ress=await axios.post(`${apilink}/api/orderbook/addOrderstudyContent`,{
//           ...data,
//           ship_order_id:res.data.result.order_id,
//           ship_shipment_id:res.data.result.shipment_id
//         },{
//           headers: {
//             Authorization: token,
//           },
//         })

//         if(ress.data.success) {
//         // console.log(ress.data.url)
//         // window.location.href = ress.data.url;
       
//         // setTimeout(() => {
//         //   window.location.href=ress.data.url
//         //  }, 1000);
// console.log(ress.data.msg)
// alert.info(ress.data.msg)
//         }else{
//           alert.error(ress.data.msg)
//         }
     


//       }else{
//         alert.error(res.data.msg)
//       }

// }else{
//   alert.error(result.data.msg)
// }


   
      


      setTimeout(() => {
        setLoading(false);
        setPageloading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    if (stepcount == 2) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [stepcount]);

  return (
    <>
      <div className="section_page">
        <div className="container   ">
          <div className="row mt-5">
            <div className="col-12">
              <h3>Place Your Order</h3>
              <form onSubmit={onSubmitCart}>
                {stepcount == 1 && (
                  <>
                    <div className="card mt-2">
                      <div className="card-header">
                        <p className="m-0"> Personal Details</p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className=" col-lg-4 col-md-6 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter name</p>
                              <input
                                type="text"
                                placeholder="Full Name"
                                class="form-control"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className=" col-lg-4 col-md-6 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter Email address</p>
                              <input
                                type="text"
                                placeholder="Email address"
                                class="form-control"
                                required
                                value={billing_email}
                                onChange={(e) =>
                                  setBilling_email(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className=" col-lg-4 col-md-6 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter Phone number</p>
                              <input
                                type="text"
                                placeholder="Phone number"
                                class="form-control"
                                required
                                value={billing_phone}
                                onChange={(e) =>
                                  setBilling_phone(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-header">
                        <p className="m-0"> Address Details</p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className=" col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>House / Building No.</p>
                              <input
                                type="text"
                                placeholder="House / Building No."
                                class="form-control"
                                name="address"
                                required
                                value={billing_address}
                                onChange={(e) =>
                                  setBilling_address(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className=" col-lg-6 col-md-6 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Nearby place / Street Name </p>
                              <input
                                type="text"
                                placeholder="Street Name"
                                class="form-control"
                                required
                                value={billing_address_2}
                                onChange={(e) =>
                                  setBilling_address_2(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter Pincode</p>
                              <input
                                type="text"
                                placeholder="Pincode"
                                class="form-control"
                                required
                                value={billing_pincode}
                                onChange={(e) =>
                                  setBilling_pincode(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter State</p>
                              <select
                                class="form-control"
                                value={billing_state}
                                onChange={(e) =>
                                  {
                                    setBilling_state(e.target.value)
                                  let ar = stateList.filter(
                                    (v) => v.state == e.target.value
                                  );
                                  setDistList(ar[0].districts);
                                  }
                                }
                                required
                              >
                                <option value="" selected hidden>
                                 --choose--
                                </option>
                                {stateList.map((va, i) => {
                                  return (
                                    <>
                                      <option value={va.state} key={i}>
                                        {va.state}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter District</p>
                              <select
                              class="form-control"
                                       value={billing_city}
                       onChange={(e) =>
                         setBilling_city(e.target.value)
                       }
                        required
                      >
                        <option value="" selected hidden>
                          --choose--
                        </option>

                        {distList.map((v) => {
                          return <option value={v}>{v}</option>;
                        })}
                      </select>
                              {/* <input
                                type="text"
                                placeholder="City"
                                class="form-control"
                                required
                                value={billing_city}
                                onChange={(e) =>
                                  setBilling_city(e.target.value)
                                }
                              /> */}
                            </div>
                          </div>
                          
                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Enter Country</p>
                              <input
                                type="text"
                                placeholder="Country"
                                class="form-control"
                                required
                                value={billing_country}
                                onChange={(e) =>
                                  setBilling_country(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-header">
                        <p className="m-0"> Product Details</p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className=" col-lg-6 col-md-12 col-sm-12 col-12 ">
                            <div class="form-group">
                              <p>Book Name</p>
                              <input
                                type="text"
                                placeholder="Bookname"
                                class="form-control"
                                required
                                value={book_name}
                                onChange={(e) => setBook_name(e.target.value)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className=" col-lg-3 col-md-6 col-sm-12 col-12 ">
                            <div class="form-group">
                              <p>Book Publisher</p>
                              <input
                                type="text"
                                placeholder="Book Publisher"
                                class="form-control"
                                required
                                value={book_pub}
                                onChange={(e) => setBook_pub(e.target.value)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className=" col-lg-3 col-md-6 col-sm-12 col-12 ">
                            <div class="form-group">
                              <p>Book Language</p>
                              <input
                                type="text"
                                placeholder="Book Language"
                                class="form-control"
                                required
                                value={book_lang}
                                onChange={(e) => setBook_lang(e.target.value)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Book Price</p>

                              <input
                                type="text"
                                placeholder="Country"
                                class="form-control"
                                required
                                value={book_price}
                                onChange={(e) => setBook_price(e.target.value)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Qty</p>
                              <select
                                class="form-control"
                                value={book_qty}
                                onChange={(e) => {
                                  setBook_qty(e.target.value);
                                  setTotal_book_price(
                                    e.target.value * book_price
                                  );
                                  setPayment_with_shipping(
                                    e.target.value * book_price
                                  );
                                  setDeliveryPrice(bookNumber>1 ? 180 * e.target.value :80 * e.target.value);
                                  setPayment_with_shipping(
                                    e.target.value * book_price +
                                     (bookNumber>1 ? 180 * e.target.value :80 * e.target.value)
                                  );
                                  setBook_w(Math.ceil(e.target.value * book_w));
                                }}
                              >
                                {[1, 2, 3].map((v, i) => {
                                  return <option value={v}>{v}</option>;
                                })}
                              </select>
                            </div>
                          </div>

                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Total Book Price</p>
                              <input
                                type="text"
                                placeholder="Total Book Price"
                                class="form-control"
                                required
                                value={total_book_price}
                                onChange={(e) =>
                                  setTotal_book_price(e.target.value)
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className=" col-lg-3 col-md-4 col-sm-6 col-12 ">
                            <div class="form-group">
                              <p>Delivery Price</p>
                              <input
                                type="text"
                                placeholder="Delivery Price"
                                class="form-control"
                                required
                                value={deliveryPrice}
                                
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button type="submit" className="btn btn-primary">
                        Save & Next
                      </button>
                    </div>
                  </>
                )}
                {stepcount == 2 && (
                  <>
                    {/* <div className="card mt-2">
              <div className="card-header">
                <p className="m-0"> Couriers Details</p>
              </div>
              <div className="card-body">
               <div className="row">
               {
                deliveryList.length > 0 ?(
                  <>
                  {
                deliveryList?.map((val,i)=>{
                  return(
                    <>
                    
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                  <div className="card p-3">
                   <div className="">
                    <div>
                      <input type="radio" value={val.courier_company_id} name="courier" onChange={(e)=>{
                        setDeliveryId(e.target.value);
                        setPayment_with_shipping(parseInt(total_book_price) + Math.ceil(val.rate))
                      }} required />
                    </div>

                    <div className='pl-3'>
                    <h4>{val.courier_name}</h4>
                    <h5><span className='text-custom'>Price:</span> ₹ {Math.ceil(val.rate)}</h5>
                    <p className='m-0'> <span className='text-custom'>Estimated Delivery Date:</span> {val.etd}</p>
                    <p><span className='text-custom'>Rating :</span> {val.rating}</p>
                    </div>
                   </div>



                  </div>
                </div>
                    </>
                  )
                })
               }
                  </>
                ):(
                  <>
                  <div className="p-2 text-center">
                    <p>Loading...</p>
                  </div>
                  </>
                )
               }
               </div>
               
              </div>
            </div> */}
                    <div className="card mt-2">
                      <div className="card-header">
                        <p className="m-0"> Delivery Details</p>
                      </div>
                      <div className="card-body p-3">
                        <h4>
                          <span className="text-custom">Book Price:</span> ₹{" "}
                          {Number(total_book_price).toFixed(2)}
                        </h4>
                        <h4>
                          <span className="text-custom">Tax:</span> ₹{" "}
                          0.00
                        </h4>
                        <h4>
                          <span className="text-custom">Delivery Price:</span> ₹{" "}
                          {Number(deliveryPrice).toFixed(2)}
                        </h4>
                        <h4>
                          <span className="text-custom">Total Price:</span> ₹{" "}
                          {Number(payment_with_shipping).toFixed(2)  }
                        </h4>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className={
                          loading ? "dis btn btn-primary" : "btn btn-primary"
                        }
                        disabled={loading}
                      >
                        PayNow {payment_with_shipping}
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BuyNow;
