import React, { useEffect, useState } from "react";
import "./College.css";
import BanarSlider from "../../component/slider/BanarSlider";
import { apilink, path } from "../../data/fdata";
import { NavLink } from 'react-router-dom'
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ColCourseCard from "./ColCourseCard";

const CollegeDetails = () => {
  const [pageLoading,setPageLoading]=useState(false)
  const [errorFound, setErrorFound] = useState(false);
  const {clgid}= useParams()
  const [collegeDetails,setCollegeDetails]=useState({})
 const history= useHistory()

  const getCollegeById=async(id)=>{
    setPageLoading(true)

const res=await axios.get(`${apilink}/api/college/getCollegeById/${id}`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCollegeDetails(res.data.result)
    }else{
      setErrorFound(true)
    }
    setTimeout(() => {
      setPageLoading(false)
    }, 1500);
  }

  useEffect(()=>{
    if(clgid)
    {
      getCollegeById(clgid)
    }

  },[clgid])  


  if(errorFound)
  {
    history.push("/something-wrong")
  }

  return (
    <>
     <div className="section_page">
      {
       collegeDetails?.col_images?.length > 0 && (
        <>
        
        <BanarSlider clgid={clgid} image={collegeDetails?.col_images} />
        </>
       )
       
      }

      
       
        <div className="container-fluid    ">
          <div className="row mt-5">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 mx-auto">
              <h1 className="text-col title_font text-center">Find best courses at <span className="text-custom">{collegeDetails?.col_name}</span></h1>
              <div className="row mt-5">
              {
                  collegeDetails?.col_courses?.map((val,ind)=>{
                    return(
                      <>
                      <ColCourseCard courseId={val.courseId} />
                      
                      </>
                    )
                  })
                }
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  {
                    collegeDetails?.col_location &&  <iframe src={collegeDetails?.col_location} width="100%" height="350" style={{'border':"none"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  }
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
  pageLoading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
     
    </>
  );
};

export default CollegeDetails;