import React, { useEffect, useState } from 'react'
import "../../Student.css";
import SideBar from "../../component/sidebar/SideBar";
import { useHistory , NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie';
import { apilink } from "../../../../data/fdata";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import { useAlert } from 'react-alert';
import MyOrderCompo from '../../component/studentcompo/MyOrderCompo';
import { useRef } from 'react';


const MyOrders = () => {

  const tableContainerRef = useRef(null);

  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };
   
  const [apiToken, setApiToken] = useState("");

    const [pageloading, setPageloading] = useState(false);
    const token = Cookies.get('_edushark_user_access_token');
    const his = useHistory();
    const alert=useAlert()

    const [userData,setUserData]=useState({})
    const [myData, setMyData] = useState([]);
    const [myDatac, setMyDatac] = useState([]);
    

    const [orderState, setOrderState] = useState(1);

    const [pageNo, setPageNo] = useState(0);

  const perpage = 5;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const dataallc = myDatac.slice(pagevisit, pagevisit + perpage);
  const boxnoc = Math.ceil(myDatac.length / perpage);

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };
  
    useEffect(async() => {
      if(token) {
        setPageloading(true)
          const res=await axios.get(`${apilink}/api/user/authVerify`,{
              headers: {
                Authorization: token,
              },
            })
            // console.log(res.data)
            if(!res.data.success)
            {
              Cookies.remove('_edushark_user_access_token');
             localStorage.removeItem('_edushark_user_access_login');
            console.clear();
            window.location.href = '/login';
            }else{
             
            setUserData(res.data.userInfo)
                
              
    
            }
            setTimeout(() => {
              setPageloading(false)
            }, 1500);
           
      }else{
        his.push("/login")
      }
    }, []);

    const allOrderstudyContent=async()=>{
        const res=await axios.get(`${apilink}/api/orderstudycontent/myOrderstudyContent`,{
          headers: {
            Authorization: token,
          },
        })
      //  console.log(res.data)
       if(res.data.success)
       {
        setMyDatac(res.data.result)
       
       
       }else{
        alert.error(res.data.msg)
       }
      }

      const  getMyBookOrders=async()=>{
        const res=await axios.get(`${apilink}/api/orderbook/getMyBookOrders`,{
          headers: {
            Authorization: token,
          }
        })
        // console.log(res.data)
        if(res.data.success)
        {
          setMyData(res.data.result)
        }else{
          alert.error(res.data.msg)
        }
      }

      const getToken=async()=>{
        const res = await axios.get(`${apilink}/api/shiprocket/loginUser`);
        // console.log(res.data)
        if (res.data.success) {
          setApiToken(res.data.result.token);
        }else{
          alert.error(res.data.msg)
        }
      }


    
      useEffect(()=>{
        getToken()
        allOrderstudyContent()
        getMyBookOrders()
      },[])

  return (
   <>
   
   <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid mt-4">
          <div className="row">
                <div className="col-12 mb-2">
                {/* <button className='sml_btn active_btn' >Purches Books</button> */}

                  {
                    orderState == 1 ?(
                      <>
                      <button className='sml_btn active_btn m-1' onClick={()=>setOrderState(1)}>Purches Classes</button>
                  <button className='sml_btn m-1' onClick={()=>setOrderState(2)}>Purches Books <span className='bg-warning p-1 rounded'>{myData.length}</span></button>
                      </>
                    ):(
                      <>
                      
                      
                      <button className='sml_btn  m-1' onClick={()=>setOrderState(1)}>Purches Classes</button>
                  <button className='sml_btn active_btn m-1' onClick={()=>setOrderState(2)}>Purches Books <span className='bg-warning p-1 rounded'>{myData.length}</span></button>
                      </>
                    )
                  }

                </div>
               {
                orderState == 1 ?(
                  <>
                   <div className="col-12">
                 
                    <div className="card py-5 px-3">
                  <div className="text-center">
                  <button className='btn btn-primary px-3 '  onClick={() => {
                         
                         window.open("https://class.edushark.in", "_blank")
                       }}> Live Classes Orders</button>
                  </div>
                    </div>
                {/* <div className="card p-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Orders</h5>
                 
                  </div>

                  {
                  dataallc.length > 0 ?(
                    <>
                     <div class="table-responsive ">
          <table class="table table-borderless dash">
            <thead>
              <tr>
                <th className="w_20">OrderId</th>
                
                <th className="w_60">ContentDetails</th>
               <th className='w_20'>Amount</th>
               <th className='w_20'>PayStatus</th>
                <th className="sm_w">Status</th>
               
              </tr>
            </thead>
            <tbody className=''>
              {
                dataallc?.map((val,ind)=>{
                  return(
                    <>
                    <tr>
                <td><small>{val._id}</small></td>
                
                <td><NavLink to={`/student/content/${val.content_id?._id}`}>{val.content_id?.cor_name}</NavLink></td>
              <td>
                
                ₹ {Number(val.payment_amount).toFixed(2)}
              </td>
              <td>
              {val.payment_status  ? "PAID": "UNPAID"}
              </td>
              
                <td> 

                {val.study_status=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {val.study_status=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {val.study_status=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}


                </td>
                
              </tr>
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataallc.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxnoc}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div> */}
                </div>
                  
                  
                  </>
                ):(
                  <>

<div className="col-12">
                <div className="card p-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Orders</h5>
                 
                  </div>
                  <div>
         <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
</button>
      <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
</button>
         </div>

                 {
                  dataall.length > 0 ?(
                    <>
                     <div class="table-responsive " ref={tableContainerRef}>
          <table class="table table-borderless dash ">
            <thead>
              <tr>
                <th className="w_20">OrderId</th>               
                <th className="w_60">ProductDetails</th>  
                <th className='w_20'>Amount</th>
                <th className='w_20'>PayStatus</th>
                <th className='w_20'>TrackingId</th>
                <th className="sm_w">Status</th>
                <th className="sm_w">Operation</th>
                <th className="sm_w">Date</th>


              
                
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall?.map((val,ind)=>{
                  return(
                    <>
                   <MyOrderCompo 
                   key={ind} 
                   id={val._id} 
                   book_id={val.book_id} 
                   bookname={val.book_id?.bookname} 
                   ship_order_id={val.ship_order_id}
                   order={val}
                   token={apiToken} 
                   sessionToken={token} 
                   alert={alert} 
                   setPageloading={setPageloading}

                 />
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>
                    
                    </>
                  ):(
                    <>
                    <div className="py-4 text-center">
                      <h5>No Item Found</h5>
                    </div>
                    </>
                  )
                 }

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                  </div>
                </div>
                  
                  
                  </>
                )
               }
              </div>

            
          </div>
          </div>
        </div>
      </div>
   
   
   {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
   </>
  )
}

export default MyOrders