import React, { useEffect, useState } from 'react'
import { apilink, path } from '../../data/fdata'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'

const ColCourseCard = ({courseId}) => {
    const [courseDetails,setCourseDetails]=useState({})

    
 const getCourseById=async(cid)=>{
   
    const res=await axios.get(`${apilink}/api/course/getCourseById/${cid}`)
    // console.log(res.data)
    if(res.data.success)
    {
      setCourseDetails(res.data.result)
    }
    
  
   }
   
    useEffect(()=>{
        if(courseId)
        {
            getCourseById(courseId)
        }

    },[courseId])

    // console.log(courseDetails)
  return (
    <>
    <div className=" col-xl-4 col-lg-6 col-md-6 col-12 mb-4 ">
                  <div className="card p-2">
                    {
                        courseDetails?.cou_image &&  <img src={courseDetails?.cou_image} alt="" className="courseimg" />
                    }
                   
                    <div className="p-2 pt-4 text-center">
                    <h3 className='text-col title_font_sm'>{courseDetails?.cou_name && courseDetails?.cou_name}</h3>
                  <p className="short_p">{courseDetails?.cou_description && courseDetails?.cou_description}</p>
                   
                  <div className="text-center mt-4">
                    {
                        courseDetails?._id &&  <NavLink to={`/courses/${courseDetails?._id}`} className='btn btn-warning '>Enroll Now</NavLink>
                    }
                    
                  </div>
                    </div>
                  </div>
                </div>
    
    </>
  )
}

export default ColCourseCard