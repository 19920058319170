import React from 'react'
import './Policy.css'
const ShippingPolicy = () => {
  return (
    <>
    
    <div className="section_page">
    <div className="container   mt-5">
   <div className="mt-2">
   <h4>SHIPPING & DELIVERY POLICY</h4>
<p> Last updated November 05, 2023</p>
 <p>This Shipping & Delivery Policy is part of our Terms and Conditions ("Terms") and should be therefore read alongside our main Terms:</p>
<p> Please carefully review our Shipping & Delivery Policy when purchasing our products. This policy will apply to any order you place with us.
</p>
   </div>

<div className='mt-5'>
<h4>WHAT ARE OUR SHIPPING & DELIVERY OPTIONS?</h4>
<p>We use multiple courier service like Shiprocket, Ecom Express, DTDC, and Indian Post as our Surface Courier Partner to deliver your product timely.
</p>
<h5>Free Shipping</h5>
<p>We offer free Surface shipping on Orders over Rs. 2000/-. (Terms and Conditions are applied)</p>
<h5> Expedited Shipping Fees</h5>
<p> We also offer expedited shipping at the following rates:</p>
<p>Shipping cost varies from company to company. It can range anywhere from Rs. 40 to Rs. 60 to start with for items weighing up to 250g. For More Information you can click the Link given bellow.
</p>
<a target='_blank' href="https://www.shiprocket.in/shipping-rate-calculator">https://www.shiprocket.in/shipping-rate-calculator</a>
 <p>Surface 7-10 days</p>
 <p>If you select an expedited shipping option, we will follow up after you have placed the order with any additional shipping information.
</p>
<p>All times and dates given for delivery of the products are given in good faith but are estimates only. </p>
<p>Once Your Order is packaged, we will send an Email with your tracking information in your Registered Mail id. </p>

</div>


<div className='mt-5'>
<h4>DO WE DELIVER INTERNATIONALLY?</h4>
<p>We do not offer international shipping.</p>

 
</div>

<div className='mt-5'>
<h4>WHAT HAPPENS IF MY ORDER IS DELAYED?</h4>
<p>If delivery is delayed for any reason we will let you know as soon as possible and will advise you of a revised estimated date for delivery.
</p>

</div>
<div className='mt-5'>
<h4>QUESTIONS ABOUT RETURNS?</h4>
<p>If you have questions about returns, please review our Return Policy
</p>
</div>

<div className="mt-5">
    <h4>HOW CAN YOU CONTACT US ABOUT THIS POLICY? </h4>
<p> If you have any further questions or comments, you may contact us by:</p>
<p> Email: <a href="mailto:iedushark@gmail.com">iedushark@gmail.com</a></p>

</div>


        </div>
    </div>
    
    
    </>
  )
}

export default ShippingPolicy