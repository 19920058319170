import React, { useEffect, useState } from "react";
import "../../Student.css";
import SideBar from "../../component/sidebar/SideBar";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie';
import { apilink } from "../../../../data/fdata";
import axios from "axios";
import Accordion from "../../../../component/accord/Accordion";
import { saveAs } from 'file-saver';
import { useAlert } from "react-alert";


const MyDetails = () => {
  const [userData, setUserData] = useState([]);
  const [pageloading, setPageloading] = useState(false);
  const token = Cookies.get('_edushark_user_access_token');
  const his = useHistory();
 const alert= useAlert()
  const [applicationStu,setApplicationStu] =useState({})
  const [educationObj,setEducationObj] =useState({})
  const [educationCor,setEducationCor] =useState("")




  useEffect(async() => {
    if(token) {
      setPageloading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            
              setUserData(res.data.userInfo)
            
  
          }
          setTimeout(() => {
            setPageloading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

  const getMyApplication=async()=>{
    const res=await axios.get(`${apilink}/api/application/getMyApplication`,{
      headers: {
        Authorization: token,
      },
    })
   
    // console.log(res.data.result)
    if(res.data.success)
    {
      setApplicationStu(res.data.result)

      // if(res.data.result.course_name=="DPHARM")
      // {
      //   setEducationObj(res.data.result.dpharm_form)
      //   setEducationCor(res.data.result.course_name)

      // }
      // if(res.data.result.course_name=="BED")
      // {
      //   setEducationObj(res.data.result.bed_form)
      //   setEducationCor(res.data.result.course_name)


      // }
      // if(res.data.result.course_name=="DELED")
      // {
      //   setEducationObj(res.data.result.dled_form)
      //   setEducationCor(res.data.result.course_name)

      // }
      
    }else{
      alert.error(res.data.msg)
    }
  }

  const agreementpdf=async(aid)=>{
    setPageloading(true)
    const res=await axios.post(`${apilink}/api/pdf/agreement-pdf`,{
      aid
    })
   
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `agreement_pdf_${aid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
    //  alert.error(res.data.msg)
     alert.error("Something went wrong!!")
    }
    setPageloading(false)
  }
  const applicationpdf=async(aid)=>{
    setPageloading(true)
    const res=await axios.post(`${apilink}/api/pdf/application-pdf`,{
      aid
    })
   
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `application_pdf_${aid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
    //  alert.error(res.data.msg)
     alert.error("Something went wrong!!")
    }
    setPageloading(false)
  }

  
  useEffect(()=>{
    getMyApplication()
  },[])


  const completeApplicationPayment=async (feeid , feesamount) => {

  

    setPageloading(true)
    const da={
      MUID: "MUID" + Date.now(),
    transactionId: 'T' + Date.now(),
    feeid,
    feesamount
    }
    const res=await axios.post(`${apilink}/api/application/completeApplicationPayment`,da,{
      headers: {
        Authorization: token,
      },
    })
  
    if(res.data.success)
  {
    //  console.log(res.data.url)
   setTimeout(() => {
    setPageloading(false)
   }, 1500);
  
    setTimeout(() => {
      window.location.href=res.data.url
     }, 3000);
  
  }else{
    setTimeout(() => {
      setPageloading(false)
     }, 1500);
    
    
    alert.error(res.data.msg)
  
  }
  
  }

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
           {
            userData?.isApproved ?(
              <>
              <div className="dd_flex">
              <h4 className='text-custom mb-4'>Student Details</h4>
              {
                applicationStu.isReverify &&  <button onClick={()=>{
                  his.push(`/student/${applicationStu.course_name}/application/${applicationStu._id}/update`)
                }} className='sml-btn px-3 py-2 mr-1 bg-danger'> Update</button>
              }
              </div>
              {
                applicationStu.isReverify && (
                  <>
<div class="alert alert-warning">
 
 This application is given for re update.
 
 
 </div>
                  </>
                )
              }
               
               {
                 applicationStu.ApplicationStatus == "APPROVED" && 
                 (
                  <>
 <div className="dd_flex">
             
                        


             <button onClick={()=>applicationpdf(applicationStu._id)} className='sml-btn px-3 py-2 mr-1'> <span className='d-lg-block d-md-block d-none'> <i className='fa fa-download'></i> Download Application</span> <span className=' d-md-none d-bock'><i className='fa fa-download'></i> Application</span> </button>
                          
             <button onClick={()=>agreementpdf(applicationStu._id)} className='sml-btn px-3 py-2 mr-1'> <span className='d-lg-block d-md-block d-none'> <i className='fa fa-download'></i> Download Agreement</span> <span className=' d-md-none d-bock'><i className='fa fa-download'></i> Agreement</span> </button>
             
                           </div>

                  </>
                 )
               }
             
               {
                applicationStu &&  <div className="accordion_whole">
                <Accordion index={1} title="Course Details" data={applicationStu}  />
                <Accordion index={2} title="Personal Details" data={applicationStu}  />
            <Accordion
            index={3}
              title="Contact & Address Details"
            data={applicationStu}
            />
            <Accordion
            index={4}
              title="Education Details"
            data={applicationStu}
            // educationCor={educationCor}
            // educationObj={educationObj}
            />
            <Accordion
            index={5}
              title="Documents Details"
            data={applicationStu}
            />
            <Accordion
            index={6}
              title="Payment Details"
            data={applicationStu}
            />
                </div>
               }
           
              
              </>
            ):(
              <>

             {
              applicationStu?._id ? (
                <>
<div className="card rounded py-3  px-2 text-center">
              <h5 className="mb-3 mt-4">Hi <span className="text-custom">{userData?.name}</span>,</h5>
              {
                applicationStu?.paymentStatus ?(
                  <>
                  
                  {
                applicationStu.ApplicationStatus == "PENDING"   &&  (
                  <>
                   <h5 className=""> Your {applicationStu.course_name} application is not approved yet. Need help ?<span className='cur' onClick={()=>his.push("/contact")}> <b className="text-custom">Contact with us</b> </span> </h5>
                  </>
                )
              }

{
                applicationStu.ApplicationStatus == "REJECTED"   &&  (
                  <>
                   <h5 className=""> Your {applicationStu.course_name} application is <span className="text-danger">Rejected</span>. Need help ?<span className='cur' onClick={()=>his.push("/contact")}> <b className="text-custom">Contact with us</b> </span> </h5>
                  </>
                )
              }
                  </>
                ):(
                  <>
                 <h5 className="text-danger"> You have not paid for {applicationStu.course_name} application.</h5>
                 <div className="text-center">
                 <button className="sml_btn bg-warning" onClick={()=>completeApplicationPayment(applicationStu._id,applicationStu.paymentAmount)}>Pay Now</button>
                 </div>
                  </>
                )
              }
              
             
<div className="text-center my-4">
<NavLink to="/purchase-books" className="btn btn-primary" >Find Books</NavLink>
</div>
             </div>

                </>
              ):(
                <>
<div className="card rounded py-3  px-2 text-center">
              
              <h5 className="mt-4">Hi <span className="text-custom">{userData?.name}</span> , You did not submit any application. </h5>
<div className="text-center my-4">
<NavLink to="/courses" className="btn btn-primary" >Find Our Degree Courses</NavLink>
</div>
             </div>

                </>
              )
             }
              
              </>
            )
           }
          </div>
        </div>
      </div>
      {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
}
    </>
  );
};

export default MyDetails;
