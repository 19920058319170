import React from 'react'
import './Policy.css'

const TermCondition = () => {
  return (
    <>
     <div className="section_page">
    <div className="container   mt-5">
    <h1>Terms and Conditions</h1>
          <br />
          <p className="fn_14">Last Updated: 20/11/2023</p>

          <p>Welcome to <a href="https://edushark.in" target="_blank">Edushark.in </a> ("us," "we," or "our"). By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. <br /> Please read these terms carefully before using our website.</p>

          <h5><b>1. Scope of Services</b></h5>
          <div className="pl-3">
          
        <p className='mt-2'><a href="https://edushark.in" target="_blank">Edushark.in </a> provides educational consultancy services, including the sale of books, recorded video courses, live online classes, payment processing through a secure payment gateway, student admissions, and courier delivery services for books within India.</p>
          </div>


          <h5><b>2. Ordering and Payment</b></h5>
          <div className="pl-3">
          <b>2.1 Payment:</b>
          <p className="mt-2">
          All payments for our services, including books, recorded video courses, and online classes, are processed through a secure payment gateway. By making a payment, you agree to the terms and conditions set by the payment gateway.
          </p>
      <b>2.2 Order Confirmation:</b>
          <p className='mt-2'> Upon successful payment, you will receive an order confirmation via email. This confirmation serves as acknowledgment of your order.</p>
       
          </div>

          <h5><b>3. Student Admissions</b></h5>
          <div className="pl-3">
          <b>3.1 Admission Process:</b>
          
        <p className='mt-2'>We facilitate the admission process for students applying to different colleges. The admission process is subject to the policies and guidelines set by the respective colleges.</p>
         <b>3.2 Refund Policy:</b>
         <p className='mt-2'>We provide refunds for admissions only in cases where a student is rejected due to subject unavailability or seat unavailability.</p>
          </div>
          <h5><b>4. Books and Course Materials</b></h5>
          <div className="pl-3">
          <b>4.1 Delivery:</b>
        <p className='mt-2'>Books purchased through <a href="https://edushark.in" target="_blank">Edushark.in </a> will be delivered through courier services within India to the address provided during the ordering process.</p>
        <b>4.2 Refund for Book Orders:</b>
        <p className='mt-2'>Refunds for book orders will be issued only in cases where we cancel the order or if the books are returned in unused condition within a specified period.</p>
          </div>
          <h5><b>5. Refund Policy</b></h5>
          <div className="pl-3">
          
          <b>5.1 Rejection of Admission:</b>
        <p className='mt-2'>Refunds for admission fees will be processed if a student is rejected due to subject unavailability or seat unavailability.
</p>
        <b>5.2 Cancellation of Book Orders:</b>
        <p className='mt-2'>Refunds for book orders will be issued if we cancel the order for any reason.</p>

        <b>5.3 Refund Process:</b>
        <p className='mt-2'>To request a refund, please contact our support team at support@edushark.in. Refunds will be processed within a reasonable time frame.</p>
          </div>
          <h5><b>6. Contact Information</b></h5>
          <div className="pl-3">
          
          <p className='mt-2'>For any queries or concerns regarding these terms and conditions, please contact us at:</p>

<p className='mt-2'>Email: <a href="mailto:support@edushark.in">support@edushark.in</a></p>

Address: <br />
Locality - Guripaara ,<br />
Po - Azimganj ,<br />
PIN - 742122 ,<br />
State - West Bengal ,<br />
Country - India .
          </div>
          <br />
          <h5><b>7. Changes to Terms and Conditions</b></h5>
          <div className="pl-3">
          
        <p className='mt-2'><a href="https://edushark.in" target="_blank">Edushark.in </a> reserves the right to update or modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our website.</p>
          </div>

        </div>
    </div>
    
    </>
  )
}

export default TermCondition