import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './SideBar.css';
import { stuheaderNav } from '../../../../data/fdata';


const SideBar = () => {
  const { pathname } = useLocation();
  const active = stuheaderNav.findIndex((e) => e.path === pathname);
  const loc = useLocation();
  // console.log(loc.pathname.split('/')[1].length);

  return (
    <>
      <div className="side_navbar_inner">
        {stuheaderNav?.map((val, i) => {
          return (
            <>
            {
              val.display =="Purchase Classes" ?
              (
                <>
<a  key={i} href={val.path} target='_blank'>{val.display}</a>
                </>
              ) :(
                <>
                <NavLink
                key={i}
                to={val.path}
                className={i === active ? 'active' : ''}
              >
                {val.display}
              </NavLink>

                </>
              )
            }
              
            </>
          );
        })}
      </div>
    </>
  );
};

export default SideBar;
