import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Error = () => {
  const his = useHistory();
 const [errorShow,setErrorShow]= useState(true)
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    setErrorShow(true)

    setTimeout(() => {
      setErrorShow(false)
    }, 2000);

  }, []);

  return (
    <>
      <div className="error">
        <div className="container">
          <div className="row">
            
              {
                errorShow ?(
                  <>
                 <div className="col-12 bg-box">
                 <div class="loader"></div>
                 </div>
                  
                  </>
                ):(
                  <>
                  <div className="col-md-8 col-12 mx-auto">
                  <h1>404 Error !! Page Not Found</h1>
              <button
                className="btn btn-primary mt-3"
                onClick={() => his.goBack()}
              >
                Go Back
              </button>
                  </div>
                 
                  </>
                )
              }
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
