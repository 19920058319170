import React, { useEffect, useState } from "react";
import "../../Student.css";
import SideBar from "../../component/sidebar/SideBar";
import { NavLink, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from 'js-cookie';
import { apilink } from "../../../../data/fdata";
import axios from "axios";
import { useAlert } from "react-alert";
import BedUpdateForm from "../../component/formupdate/BedUpdateForm";
import DeledUpdateForm from "../../component/formupdate/DeledUpdateForm";
import DpharmUpdateForm from "../../component/formupdate/DpharmUpdateForm";

const EditApplication = () => {
    const [pageloading, setPageloading] = useState(false);
    

  const token = Cookies.get('_edushark_user_access_token');
  const his = useHistory();
 const alert= useAlert()
const {coursename,applyid}= useParams()
  const [applicationStu,setApplicationStu] =useState({})
  const [sessionData,setSessionData] = useState("")
  const [userDetails,setUserDetails]=useState({})


// console.log(applyid)
useEffect(async() => {
    if(token) {
      setPageloading(true)
        const res=await axios.get(`${apilink}/api/user/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_user_access_token');
           localStorage.removeItem('_edushark_user_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            setUserDetails(res.data.userInfo)
             
            
  
          }
          setTimeout(() => {
            setPageloading(false)
            
          }, 1500);
         
         
    }else{
      his.push("/login")
    }
  }, []);

const allApplicationsById=async()=>{
    try {
      const res=await axios.get(`${apilink}/api/application/allApplicationsById/${applyid}`)
    // console.log(res.data.result)
    if(res.data.success)
    {
      
      setApplicationStu(res.data.result)
      if(!res.data.result.isReverify)
        {
          his.push("/something-wrong")
        }
      
    }else{
    //   alert.error(res.data.msg)
    his.push("/something-wrong")
    }
    } catch (error) {
      his.push("/something-wrong")
    }
  }
  // const getAllSessions=async()=>{
    

  //   const res=await axios.get(`${apilink}/api/feestruct/getSessions`)
  //   // console.log(res.data)
  //   if(res.data.success){
  //       setSessionData(res.data.result[0].session_app)
          
  //   }else{
  //       alert.error(res.data.msg)
  //   }
  // }

  useEffect(()=>{
allApplicationsById()
let bol=["BED","DELED","DPHARM"].includes(coursename)
if(!bol)
{
  his.push("/something-wrong")
}

  },[])




  if(!pageloading)
  {
    if(coursename=="BED")
    {
      return (
        <>
        <BedUpdateForm  sessionData={sessionData} datalist={applicationStu} name={userDetails.name} email={userDetails.email} phone={userDetails.phone} />
        </>
      )

    }else if(coursename=="DELED")
    {
return (
  <>
  <DeledUpdateForm  sessionData={sessionData}  datalist={applicationStu} name={userDetails.name} email={userDetails.email} phone={userDetails.phone} />
  </>
)
    }else if(coursename=="DPHARM")
    {
      return(
        <>
        <DpharmUpdateForm  sessionData={sessionData}  datalist={applicationStu} name={userDetails.name} email={userDetails.email} phone={userDetails.phone} />
        </>
      )

    }

  }



  return (
    <>
     <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
         
              <div className="enrollbox">
     <div className="loadingio-spinner-blocks-n2or5axwwk">
      <div className="ldio-3rgqhfk5x79">
        <div style={{ left: '38px', top: '38px', animationDelay: '0s' }}></div>
        <div style={{ left: '80px', top: '38px', animationDelay: '0.125s' }}></div>
        <div style={{ left: '122px', top: '38px', animationDelay: '0.25s' }}></div>
        <div style={{ left: '38px', top: '80px', animationDelay: '0.875s' }}></div>
        <div style={{ left: '122px', top: '80px', animationDelay: '0.375s' }}></div>
        <div style={{ left: '38px', top: '122px', animationDelay: '0.75s' }}></div>
        <div style={{ left: '80px', top: '122px', animationDelay: '0.625s' }}></div>
        <div style={{ left: '122px', top: '122px', animationDelay: '0.5s' }}></div>
      </div>
    </div>
    <h5>Loading...</h5>
        </div>
              
           
          </div>
        </div>
      </div>
      {/* {
  pageloading && (
    <>
    <div className="pageloading">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    </>
  )
} */}
    </>
  )
}

export default EditApplication