import React from 'react'
import { NavLink } from 'react-router-dom'
import './Slider.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/navigation/navigation.min.css';

const BanarSlider = ({clgid,image}) => {
  // console.log(col_images)
    SwiperCore.use([Autoplay, Navigation]);
  return (
    <>
    <div className="banar">
        <Swiper
          navigation={true}
          modules={[Autoplay, Navigation]}
          grabCursor={true}
          spaceBetween={5}
          slidesPerView={'auto'}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {
          image.map((v, ind) => (
            <SwiperSlide>
              <NavLink to={`/colleges/${clgid}`} className="banar_link">
                <div className="banar__slider">
                  <img src={v} alt="" />
                  
                </div>
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default BanarSlider